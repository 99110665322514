import React from "react";
import PropTypes from "prop-types";


// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner2 about.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import C2acomp from "../About/sections/c2acomp.jsx";
import TeamComponent from "../About/sections/teamcomponent.jsx";
import Slide from "./sections/slide.jsx";
import PortfolioComponent from "./sections/portfoliocomponent.jsx"
const Components = () => {
    return (
        <div id="About">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                <HeaderBanner />
                    {/* <C2acomp /> */}
                    <TeamComponent />
                    {/* <Slide/> */}
                    <PortfolioComponent/>
                </div>
            </div>
            <Footer />
        </div>
    );
}


Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
