import React, {useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const BatchCodeModal = (props)=>{
    const [showModal, setShowModal] = useState(props.showModal||false);
    const [brandOption , setBrandOption] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [batchCode, setBatchCode] = useState(null);
    const [resultMsg, setResultMsg] = useState('');
    const toggle = ()=>{
        props.updateModal(!showModal);
    }

    useEffect(()=>{
        setBatchCode(null);
        setResultMsg('');
        fetchOptionData();
        setShowModal(props.showModal);
    },[props])

    const fetchOptionData = async ()=>{
        let data = await fetch('/api/batchcode/option').then(res=>res.json())
        console.dir(data)
        if(data.ok){
            setSelectedBrand(data.result[0]);
            setBrandOption(data.result);
        }
    }

    const fetchResult = async()=>{
        const params = {
            code : batchCode,
            brand : selectedBrand,
        }
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        let data = await fetch('/api/batchcode/decode?'+query).then(res=>res.json())
        console.dir(data);
        if(!data.ok){
            setResultMsg(data.message);
        }else{
            let result = data.result;
            console.dir(result)
            setResultMsg(
                "Date of manufacture : "+result.manufacture + "\n"
                + result.life+"\n"
                + result.validMonth
            )
        }
    }

    const onBrandChange = (e)=>{
        setSelectedBrand(e.target.value)
    }

    const onBatchCodeChanged = (e)=>{
        setBatchCode(e.target.value)
    }

    return (
        <Modal isOpen={showModal} toggle={toggle} >
            <ModalHeader toggle={toggle}>Cosmetic calculator</ModalHeader>
            <ModalBody>
                <Form className="row">
                    <FormGroup>
                        <Label for="exampleSelect">Select Brand</Label>
                        <Input onChange={onBrandChange} value={selectedBrand} type="select" name="select" id="exampleSelect">
                            {brandOption.map(item=>{
                                return (<option value={item.value}>{item.name}</option>)
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                        <Label htmlFor="batchcode">Batch code</Label>
                        <Input value={batchCode} onChange={onBatchCodeChanged} type="text" className="form-control" id="batchcode" placeholder="배치코드를 입력하세요" />
                    </FormGroup>
                </Form>
                <pre style={ {display : 'inline', whiteSpace: 'pre-wrap'}}>{resultMsg}</pre>

            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={fetchResult} >Check</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

export default BatchCodeModal;