/* eslint-disable */
import React, { useState, } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';

import logo from '../../assets/images/logos/bbrv Logo.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                    <Navbar className="navbar-expand-lg h6-nav-bar">
                        <NavbarBrand href="/">
                            <p className="title font-bold text-white">🦄BE BRAVE Inc.</p>
                            {/* <img src={logo} alt="bebrave" className="bebraveLogo"/> */}
                        </NavbarBrand>
                        <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                        <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 justify-content-end" id="h6-info">
                            <Nav navbar className="ms-auto">
                                <NavItem>
                                    <Link className="nav-link font-bold" to={"/"}>
                                        Home
                                    </Link>
                                </NavItem>
                                {/* <NavItem>
                                    <Link className="nav-link" to={"/Home2"}>
                                        Home2
                                    </Link>
                                </NavItem> */}
                                <NavItem>
                                    <Link className="nav-link font-bold" to={"/Wholesale"}>
                                        Wholesale
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link font-bold " to={"/About"}>
                                        About
                                    </Link>
                                </NavItem>  
                                {/* <NavItem>
                                    <Link className="nav-link" to={"/Career"}>
                                        Career
                                    </Link>
                                </NavItem> */}
                                <NavItem>
                                    <Link className="nav-link font-bold" to={"/Contact"}>
                                        Contact
                                    </Link>
                                </NavItem>
                                {/* <NavItem>
                                    <Link className="nav-link" to={"/Components"}>
                                        Components
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to={"/custom-components"}>
                                        Custom-Components
                                    </Link>
                                </NavItem> */}
                            </Nav>
                            {/* <div className="act-buttons">
                                <Link to="/#coming" className="btn btn-success-gradiant font-14">Upgrade To Pro</Link>
                            </div> */}
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );

}
export default Header;
