/* eslint-disable */
import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import img1 from '../../../assets/images/features/feature48/app-store.png';
import img2 from '../../../assets/images/features/feature48/play-store.png';

const C2aComponent = () => {
    return (
        <div>
            <div className="mini-spacer bg-primary text-white c2a7">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="6" className="align-self-center text-right">
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center font-light">
                                BEBRAVE Company Profile 👉</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <button className="btn btn-outline-light btn-md">Download</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">BeBrave team </h1>
                            <h6 className="subtitle">Here you can check Demos we created based on WrapKit. Its quite easy to Create your own dream website &amp; dashboard in No-time.</h6>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            {/* <div className="spacer feature42 bg-success-gradiant">
                <Container>
                    <Row className="justify-content-center wrap-feature49-box">
                        <Col lg="7" md="10" className="text-center">
                            <h2 className="title text-white">Grab our IOS or Android App from Stores</h2>
                            <h6 className="subtitle text-white op-7 m-b-20">You can relay on our amazing features list and also our customer services will be great experience for you without doubt and in no-time.</h6>
                            <a href="#" className="m-b-20"><img src={img1} alt="img" /></a>
                            <a href="#" className="m-b-20"><img src={img2} alt="img" /></a>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </div>
    );
}

export default C2aComponent;
