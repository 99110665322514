import React from "react";

import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import Home from "./views/Home/Home.jsx";
import Home2 from "./views/Home2/Home2.jsx";
import Wholesale from "./views/Wholesale/Wholesale.jsx";
import About from "./views/About/About.jsx";
import Contact from "./views/Contact/Contact";
import Career from "./views/Career/Career.jsx";
import Components from "./views/components/components.jsx";
import CustomComponents from "./views/custom-components/custom-components.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

var hist = createBrowserHistory();
root.render(
  <HashRouter history={hist}>
    <Routes>
      <Route path="/custom-components" element={<CustomComponents />} />
      <Route path="/Components" element={<Components />} />
      <Route path="/About" element={<About />} />
      <Route path="/Career" element={<Career />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Wholesale" element={<Wholesale />} />
      <Route path="/Home2" element={<Home2 />} />
      <Route path="/" element={<Home />} />
  
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
