import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';

const HeaderBanner = () => {
    return (
        <div className="static-slider-head" id='Contact'>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" md="6" className="align-self-center text-center">
                        <h1 className="title">Contact</h1>
                        <h4 className="subtitle">
                        {/* We are active in Korea, and we hope to become a new leader in the K-beauty market and are working hard to achieve our goal. */}
                        </h4>
                        {/* <Link to="/#contact" className="btn btn-md m-t-30 btn-info-gradiant font-14">Contact us</Link> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
