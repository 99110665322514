import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import ReactPlayer from  'react-player';


const HeaderBanner = () => {
    return (
        // <div className="static-slider-head" id='home'>
        <div className="header_1" id='home'>
              <ReactPlayer 
                url={[
                    "https://youtu.be/xqohHy1EtAs",
                    "https://youtu.be/gIobxIqpu7o",
                    "https://youtu.be/HxxJwebHvGo",
                ]}
                width="100%"
                height="400px"
                loop={true}
                playing={true}
                muted={true}
                controls={false}
                />
            <div className='mini-spacer static-slider-head2'>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" md="6" className="align-self-center text-left">
                        {/* <h1 className="title">Be brave</h1> */}
                        <h4 className="subtitle">
                          We are active in Korea, and we hope to become a new leader in the K-beauty market and are working hard to achieve our goal. 
                          <br/>
                          We hope Exports an average of 
                          <span className='font-bold'> USD 50M </span>a year<br/>
                            to more than  <span className='font-bold'> 100 buyers </span><br/>
                            in 20 countries around the world.
                        </h4>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    );
}


export default HeaderBanner;
