import React from "react";
import {
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

const Whatsapp ="https://api.whatsapp.com/send/?phone=%2B821045919123&text&type=phone_number&app_absent=0";
const ContactComponent = () => {
  return (
    <div>
      <div className="mini-spacer static-slider3">
      <Container>
        <Row className="justify-content-center">
        <Button type="submit" 
          className="btn-md btn-success-gradiant m-t-20 btn-arrow">
            <span>
            <a href={Whatsapp} className="link">
              {" "}
              [Whatsapp+] <i className="ti-arrow-right"></i>
            </a>
            </span>
          </Button>
      </Row>
      </Container>
      </div>
      <div className="contact1">
        <Container>
          <Row>
            <div className="spacer">
              <Row className="m-0">
                <Col lg="8">
                  <div className="contact-box p-r-40">
                    <h4 className="title">Contact us.</h4>
                    <Form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input type="text" placeholder="name" />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input type="text" placeholder="email" />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="m-t-15">
                            <Input
                              type="textarea"
                              name="text"
                              placeholder="message ( customer details )"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <Button
                            type="submit"
                            className="btn btn-info-gradiant m-t-20 btn-arrow"
                          >
                            <span>
                              {" "}
                             [No working yet. Sorry!] <i className="ti-arrow-right"></i>
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="detail-box p-40 bg-info">
                    <h2 className="text-white">be, BRAVE</h2>
                    <p className="text-white m-t-30 op-8">
                      +82 10 4591 9123
                      <br /> krsales@bebrave.co.kr
                    </p>
                    <p className="text-white op-8">
                      3F, 50, Muhak-ro 2Gil, Seongdong-gu
                      <br /> Seoul, Republic of Korea
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactComponent;
