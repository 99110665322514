/* eslint-disable */
import React, {useState} from 'react';
import { Row, Col, Container,Button } from 'reactstrap';
import BatchCodeModal from "../../../components/modal/BatchCodeModal";
import img1 from '../../../assets/images/features/feature48/app-store.png';
import img2 from '../../../assets/images/features/feature48/play-store.png';

const C2aComponent = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <div className="spacer feature42 bg-info-gradiant">
            <Container>
                <Row className="justify-content-center wrap-feature49-box">
                    <Col lg="7" md="10" className="text-center">
                        <h2 className="title text-white">BatchCode [BETA] Result</h2>
                        <h6 className="subtitle text-white op-9 m-b-20">
                        Please use our batch code inquiry service, and then we will apply it so that you can check the distribution history. It is being developed to eradicate counterfeit products for Korean products. Thank you for your support.
                        </h6>
                        <Button color="info" onClick={()=>{setShowModal(true)}}>🎈 배치코드 조회</Button>{' '}
                        {/* <a href="#" className="m-b-20"><img src={img1} alt="img" /></a>
                        <a href="#" className="m-b-20"><img src={img2} alt="img" /></a> */}
                    </Col>
                </Row>
            </Container>
            </div>
            <BatchCodeModal showModal={showModal} updateModal={(data)=>{setShowModal(data)}} />
        </div>
    );
}

export default C2aComponent;
