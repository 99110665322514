import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner1.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page

// import BannerComponent from "./sections/bannercomponent.jsx";
import Slide from "./sections/slide.jsx";
import Images from "./sections/images.jsx";

import CallToAction from "../../components/call-to-action/CallToAction"
import C2acomp from "../Home/sections/c2acomp"
// import PricingComponent from "../Home/sections/pricingcomponent.jsx";
// import FeatureComponent from "../Home/sections/featurecomponent.jsx";

const Components = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                <HeaderBanner />
                    {/* <Slide/> */}
                    <C2acomp />
                    <Images />
                    {/* <FeatureComponent /> */}
                    <CallToAction />
                </div>
            </div>
            <Footer />
        </div>
    );
}


Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
