/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <div className="footer4 b-t mini-spacer">
            <Container>
                <Row>
                    {/* <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Address</h5>
                        <p>71 Amsteroum Avenue Cronish Night, NY 35098</p>
                    </Col> */}
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Address</h5>
                        <p>3F, 50, Muhak-Ro 2Gil, Seoul, Republic of Korea </p>
                  
                        {/* <h5>Working</h5>
                        <p>Am9 - pm6
                            <br/>                            
                        Monday to Friday</p> */}
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Phone</h5>
                        <p>Reception :  +82 1045919123 <br />
                           <a href="https://api.whatsapp.com/send/?phone=%2B821045919123&text&type=phone_number&app_absent=0" className="link">whatsapp :  +821045919123</a> <br />
                           <a href="#">wechat :  +82 1045919123</a></p>
                    </Col>
                    {/* <Col lg="3" md="6" className="m-b-30"> */}
                        {/* <h5 className="m-b-20">Email</h5>
                        <p>Office :  <a href="mailto:main@bebrave.co.kr" className="link">main@bebrave.co.kr</a> <br />Offers :  <a href="mailto:krsales@bebrave.co.kr" className="link">krsales@bebrave.co.kr</a></p> */}
                    {/* </Col> */}
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Email</h5>
                        <p>Office :  <a href="mailto:main@bebrave.co.kr" className="link">main@bebrave.co.kr</a> <br />Offers :  <a href="mailto:krsales@bebrave.co.kr" className="link">krsales@bebrave.co.kr</a></p>
                    </Col>
                     <Col lg="3" md="6">
                        <h5 className="m-b-20">Direct Link</h5>
                        <div className="round-social light">
                            <a className="link"><i className="fa fa-wechat"></i></a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B821045919123&text&type=phone_number&app_absent=0" className="link"><i className="fa fa-whatsapp"></i></a>
                            <a href="mailto:krsales@bebrave.co.kr" className="link"><i className="fa fa-google-plus"></i></a>
                            {/* <a href="#" className="link"><i className="fa fa-youtube-play"></i></a> */}
                            {/* <a href="#" className="link"><i className="fa fa-instagram"></i></a> */}
                         </div>

                         <div className="d-flex font-14 justify-content-between">
                                <div className="m-t-10 m-b-10 copyright">All Rights Reserved by BEBRAVE CO.,LTD <br></br>Since 2023</div>
                                {/* <div className="links ms-auto m-t-10 m-b-10">
                                    <a href="#" className="p-10 p-l-0">Terms of Use</a>
                                    <a href="#" className="p-10">Legal Disclaimer</a>
                                    <a href="#" className="p-10">Privacy Policy</a>
                                </div> */}
                            </div>
                   </Col> 
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-md-center">
                            <div className="ms-auto m-t-10 m-b-10">
                                <img className="p-10" src="https://cdn.industrytoday.co.kr/news/photo/202309/10741_10996_4413.png" width="100" height="80px"  alt="img" />
                                <img className="p-10" src="https://kcia.or.kr/home/img/common/logo.png" width="100" height="80px"  alt="img" />
                                <img className="p-10" src="https://www.busaneconomy.com/news/photo/202204/278924_283149_5015.jpg" width="100" height="80px"  alt="img" />
                                <img className="p-10" src="http://www.ziotac.com/images/sub/sub02_03_pic01.jpg" width="100" height="80px"  alt="img" />
                                <img className="p-10" src="https://cdn.klnews.co.kr/news/photo/202308/309097_50165_1159.jpg" width="100" height="80px"  alt="img" />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
            {/* <div className='mini-spacer footer_'>
                <Container>
                <Row>
                    <Col lg="3" md="3" className="ft1">
                        <div className="">
                            <img src="https://cdn.klnews.co.kr/news/photo/202308/309097_50165_1159.jpg" width="100" height="80px"  alt="img" />
                        </div>
                    </Col>
                    <Col lg="3" md="3" className="ft2">
                        <div className="">
                            <img src="https://kcia.or.kr/home/img/common/logo.png" width="100" height="80px"  alt="img" />
                        </div>
                    </Col>
                    <Col lg="3" md="3" className="ft3">
                        <div className="">
                            <img src="http://www.ziotac.com/images/sub/sub02_03_pic01.jpg" width="100" height="80px"  alt="img" />
                        </div>
                    </Col>
                    <Col lg="3" md="3" className="ft4">
                        <div className="">
                            <img src="https://cdn.industrytoday.co.kr/news/photo/202309/10741_10996_4413.png" width="100" height="80px"  alt="img" />
                        </div>
                    </Col>
                </Row>
                </Container>
            </div> */}
        </div>
    );
}
export default Footer;
