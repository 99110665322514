import React from 'react';
// import { Container, Row, Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { Button, Container, Row, Col } from 'reactstrap';


const CallToAction = () => {
    return (
     
        <div className="coming-soon" id="contact">
            <Container className="py-5 mt-5">
                <Row>
                    <Col md="6">
                        <div className="d-flex align-items-center">
                            <div>
                                <h2 className="title text-white font-weight-bold">Be Our Partner?</h2>
                                <h4 className="subtitle font-weight-bold text-white">
                                We are exporting many high-quality Korean products besides beauty and cosmetics based in Korea.
                                </h4>
                                <h5 className="subtitle font-light text-white"> 
                                For a successful shared growth business, we hope to become your reliable partner and grow steadily with each other.
                                </h5>
                                {/* <a href="/#coming" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14">Comming Soon</a>
                                <Link to="mailto:krsales@bebrave.co.kr" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14">Email</Link>
                                <Link to="/#coming" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14">whatsapp+</Link> */}
                                <Button color="success" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14" href="mailto:krsales@bebrave.co.kr">Email</Button>{' '}
                                <Button color="primary" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14" href="https://api.whatsapp.com/send/?phone=%2B821045919123&text&type=phone_number&app_absent=0">Whatsapp</Button>{' '}

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>


     
        // <div className="coming-soon" id="coming">
        //     <Container className="py-5 mt-5">
        //         <Row>
        //             <Col md="6">
        //                 <div className="d-flex align-items-center">
        //                     <div>
        //                         <h2 className="title text-white font-weight-bold">Pro Version coming soon</h2>
        //                         <h6 className="subtitle font-light text-white">We will add Pro Version with tons of great features and multiple category demos which is ready to use...</h6>
        //                         {/* <a href="/#coming" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14">Comming Soon</a> */}
        //                         <Link to="/#coming" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14">Comming Soon</Link>
        //                     </div>
        //                 </div>
        //             </Col>
        //         </Row>
        //     </Container>
        // </div>
    );
}

export default CallToAction;
