import React from 'react';
// import { Container, Row, Col } from 'reactstrap';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

// import img1 from '../../../assets/images/ui/img6.jpg';
import img1 from '../../../assets/images/brave/cosmetics.jpg';
import img2 from '../../../assets/images/brave/perfume_.jpg';
import img3 from '../../../assets/images/brave/Supplements.jpg';
import img5 from '../../../assets/images/features/feature30/img1.jpg';

const Images = () => {
    return (

        
    <div>
        {/* <div className="mini-spacer">
            <Container className="feature30">
                <Row>
                    <Col lg="10"><img src={img5} className="rounded img-responsive" alt="wrappixel" /></Col>
                    <Col lg="5" md="7" className="text-center wrap-feature30-box">
                        <Card className="card-shadow">
                            <CardBody>
                                <div className="p-20">
                                    <span className="label label-info label-rounded">3 Step's</span>
                                    <h3 className="title">Easy and quick 3-step ordering</h3>
                                    <p>
                                    <br></br>-Product confirmation (item number, quantity, price)
                                       <br></br>-Check payment method and delivery method.
                                       <br></br>-Payment Confirmation, Delivery
                                    </p>
                                    <a className="btn btn-info-gradiant btn-md btn-arrow m-t-20" href="#"><span>Explore More <i className="ti-arrow-right"></i></span></a>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div> */}
        <div className='mini-spacer'>
            <div id="Wholesale">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="6" className="align-self-center text-center">
                            <h1 className="title">Our Wholesale business</h1>
                            <h4 className="subtitle">
                            BEBRAVE trades high-quality cosmetics in the Korean beauty market, and exports skincare and perfumes to the world through partners in the US and Europe that have signed overseas MOUs.
                            </h4>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        <div className="spacer" id="WholesaleProducts">
            <Container>
                <Row>
                    {/* <Col lg="4" className="m-b-30">
                        <h4 className="card-title">Image with round corner</h4>
                        <h6 className="card-subtitle"><code>.img-rounded</code></h6>
                        <img src={img1} alt="img" className="img-responsive img-rounded" width="200" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col> */}
                    <Col lg="4" className="m-b-30">
                        <h4 className="card-title">Cosmetic's</h4>
                        <br></br>
                        {/* <h6 className="card-subtitle"><code>.img-rounded</code></h6> */}
                        <h6 className="card-subtitle">
                        <img src={img1} alt="img" className="img-responsive img-rounded" width="200" />
                        <p className="m-t-15 m-b-0">
                        We look forward to your company's growth with <span className='font-bold'>traditional European products and high-quality Korean cosmetics</span> that lead the current trend.
                        About 200 brands are waiting for you.
                        <br></br>
                        Wherever you are in the world, our partnership means mutual growth.
                        contact us.
                        </p>
                        </h6>
                    </Col>
                    {/* <Col lg="4" className="text-center m-b-30">
                        <h4 className="card-title">Image with circle</h4>
                        <h6 className="card-subtitle"><code>.img-circle</code> Make sure the image is square not ractangle</h6>
                        <img src={img2} alt="img" className="img-circle" width="290" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col> */}
                    <Col lg="4" className="text-center m-b-30">
                        <h4 className="card-title">Perfume's</h4>
                        <br></br>
                        <h6 className="card-subtitle">
                            We handle and export 100% genuine products from <span className='font-bold'> Korea/USA/EU</span>.<br></br> &nbsp;
                           <span className='font-bold'> Our products are sourced from authorized partner in Korea , United States and Euro.</span> 
                           <br></br> &nbsp; If a fake occurs in our distribution process, we will compensate you in full.
                            We ensure our customers get the best quality premium products in different forms
                            Our multiple partners will support your offer in Europe and the US.
                        </h6>
                        <img src={img2} alt="img" className="img-responsive img-rounded" width="200" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4">
                        <h4 className="card-title">Health  Supplements</h4>
                        {/* <h6 className="card-subtitle"><code>img-thumbnail</code></h6> */}
                        <h6 className="card-subtitle">
                        <img src={img3} alt="img" className="img-responsive img-thumbnail" width="200" />
                        <p className="m-t-15 m-b-0"></p>
                        <p className="m-t-15 m-b-0">
                        Nutrients for beauty, such as  <span className='font-bold'>lactic acid bacteria, hyaluronic acid, and collagen, vitamins</span> to relieve fatigue of modern people,
                         and  <span className='font-bold'>Korean red ginseng</span> to restore vitality are preparing for export through Korea's safe manufacturing and distribution network.
                        </p>
                       </h6>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    );
}

export default Images;
