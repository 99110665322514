/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/portfolio/img1.jpg';
import img2 from '../../../assets/images/portfolio/img2.jpg';
import img3 from '../../../assets/images/portfolio/img3.jpg';
import img4 from '../../../assets/images/portfolio/img4.jpg';
import img5 from '../../../assets/images/portfolio/img5.jpg';
import img6 from '../../../assets/images/portfolio/img6.jpg';

const image1 = "https://image.ajunews.com/content/image/2017/12/03/20171203132855221843.jpg" ;
const image2 = "https://th.bing.com/th/id/OIP.2eca_NVyu4beKtGRYyFRxAHaHa?pid=ImgDet&rs=1";
const PortfolioComponent = () => {
    return (
        <div>
            <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Portfolio</h1>
                            {/* <h6 className="subtitle">Here you can check Demos we created based on WrapKit. Its quite easy to Create your own dream website &amp; dashboard in No-time.</h6> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">Our Recent works</h2>
                            <h6 className="subtitle">
                                You can relay on our amazing features list and also our customer services will be great experience for you without doubt and in no-time
                            </h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={image1} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Olive's young</h5>
                                    <p className="m-b-0 font-14">We export and supply more than 180 brands of Olive's Young.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={image2} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Primium korea Botox,Filler and Exosome's</h5>
                                    <p className="m-b-0 font-14">
                                    Korean beauty technology is already leading the world.
                                    At the request of many buyers, we have started supplying Botox filler exosomes💉.
                                    The first transaction is for Iraq buyers, but it is open to buyers from all countries including the US, Europe, China, and Japan.
                                    <br></br>
                                    Get in touch! Thank you!
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img3} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Branding & Co Agency</h5>
                                    <p className="m-b-0 font-14">Admin templates</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img4} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Zukandre Phoniex</h5>
                                    <p className="m-b-0 font-14">Branding</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img5} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Sionage Mokcup</h5>
                                    <p className="m-b-0 font-14">Wll Mockup</p>
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src={img6} alt="wrappixel kit" /></a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Mask Pack's</h5>
                                    <p className="m-b-0 font-14">
                                    Many of the brand-specific masks we supply are still immensely popular. Brands such as Mediheal and Dr. Jart can only be ordered in bulk. We do not trade by purchasing products through illegal routes. We are based in South Korea and only transact in an accurate manner that does not violate the tax laws of the Republic of Korea. 
                                    <br></br>
                                    I appreciate it.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PortfolioComponent;
