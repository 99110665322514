/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

// import img1 from '../../../assets/images/portfolio/img1.jpg';
import img2 from '../../../assets/images/portfolio/img2.jpg';
import img3 from '../../../assets/images/portfolio/img3.jpg';
import img4 from '../../../assets/images/portfolio/img4.jpg';
import img5 from '../../../assets/images/portfolio/img5.jpg';
import img6 from '../../../assets/images/portfolio/img6.jpg';

const PortfolioComponent = () => {
    return (
        <div>
            <div className="mini-spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">What we do</h1>
                            <h6 className="subtitle">
                                Wholesale &amp; Develope about Distribution Traceability System(DTS).
                                {/* &nbsp; */}
                                <br/>
                                We Upload Wholesale Supply Photo here 🚩
                                 {/* Here you can check Demos we created based on WrapKit. Its quite easy to Create your own dream website
                                 &amp; 
                                 dashboard in No-time. */}
                                </h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="mini-spacer">
                <Container>
                   {/* <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">We Recently Supply</h2>
                             <h6 className="subtitle">
                                You can relay on our amazing features list and also our customer services will be great experience for you without doubt and in no-time
                            </h6>
                           
                        </Col> 
                    </Row> */}
                    <Row className="m-t-40">
                        <Col md="4">
                            <Card className="card-shadow">
                                <a href="#" className="img-ho"><img className="card-img-top" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhbuwzI6dLxD7fdtavb6qsJGFskC3HMAxIQHn37xlz_cljsO3eyTDrYyREHBeHTVnHFSBYH174FsrbXB9nq8xYlg437qqtsPPiVYBulwzWLTd5uuTzgSYtD08MJbztP9WeUNYZPDv2PV76rVj4j8flUX1IiETaO8XGLhdbBDbOWReNQFr4G49r1FltIrGRR/s320/KakaoTalk_20230825_100234676_01.jpg"
                                 alt="wrappixel kit" /></a>
                                <CardBody>
                                <h5 className="font-medium m-b-0">Bio heal boh</h5>
                                    <p className="m-b-0 font-14">2023-08-22 </p>
                                    <p className="m-b-0 font-14">Location : US</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                
                            <a className="img-ho">
                                <img className="card-img-top" 
                                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgTcSTVeeoYl9YvtoFGLuakcY071WGmx-_EtGrN_tnbwX1AMxyn2Awe-19AOGU7OB50X0FuUnyDq2g-BarfWoJ4YyU4seokK2RkrPhc4U2Vk2-yskKX5LSpGvaL4_NUUMuWmxMCmFN1GKSyrsLOlQCss3JHhUbLJZEGI3HohAIb5LykJ4d0g8JNRC9clzBl/s320/KakaoTalk_20230825_1002346761.jpg" alt="wrappixel kit" />
                                </a>
                                <CardBody>
                                <h5 className="font-medium m-b-0">Bio heal boh</h5>
                                    <p className="m-b-0 font-14">2023-08-22 </p>
                                    <p className="m-b-0 font-14">Location : US</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                {/* <a href="#" className="img-ho"><img className="card-img-top" src={img1} alt="wrappixel kit" /></a> */}
                                <a className="img-ho">
                                <img className="card-img-top" 
                                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgCYMvhuMST197xwSPxBmSoz5Tg-tACXWm18aFJfXcrzW4o_KVGk2iCTPS30SbpRcBMdppD7APaUb0v6lnUB4pFkQ-O4UgKbnP2YCAXJ9QKxQR7jjYRJDBLHAB05lrb0f6GAvSZBM5xeSr0qY6BHkchT3aVnao_7KxNdbKLwKAeMm60Qmvuy2t3EoKVBkoZ/s320/KakaoTalk_20230825_094544009_01.jpg" alt="wrappixel kit" />
                                </a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Rejuran</h5>
                                    <p className="m-b-0 font-14">2023-08-14 </p>
                                    <p className="m-b-0 font-14">Location : CIS Country</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a className="img-ho">
                                    <img className="card-img-top" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEho1PgQsBhXBmQe4Ak__DHXTlfQtA7P3JQkDV4U_6jrhuHOd1KweKkcevrErBlUyGjau2sWvRxYA4ZGZVIbkY0p_zxuX2tEqCXRDOQin8CSJqJXSZ-tFPr5pVQVdOLLmyM5H3s77VQ1wDx-khh2NHTDenq6NocX0mzg6Jb69ryu4w72u-Nkz_MufiBPvsSY/s320/KakaoTalk_20230825_094544009.jpg" alt="wrappixel kit" />
                                    </a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">Rejuran</h5>
                                    <p className="m-b-0 font-14">2023-08-14 </p>
                                    <p className="m-b-0 font-14">Location : CIS Country</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                {/* <a href="#" className="img-ho"><img className="card-img-top" src={img1} alt="wrappixel kit" /></a> */}
                                <a className="img-ho">
                                <img className="card-img-top" 
                                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhHUQiZ1WJ-OB5m1P1wTN0_7cLLpGByJah-I7b38kJdh3OqsdnNy_UXkOAhchfSrgZGzoVYD-QGK_5gGn6n2odCztzs2n5M75qhJc23PyGhOBMNJHdEh76bJlAz3RuvAoGAaebwtQV_xPANMIiRnUd4Q6JOccrVM_5s8mzABlS_m11PkBtm5B8mXChc6jcG/s320/KakaoTalk_20230827_155915268_01.jpg" alt="wrappixel kit" />
                                </a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">CLIO 3CE BanilaCo</h5>
                                    <p className="m-b-0 font-14">2023-08-09 </p>
                                    <p className="m-b-0 font-14">Location : Vietnam</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-shadow">
                                <a className="img-ho">
                                    <img className="card-img-top" 
                                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiMUrxMtbF_Pmj68_LBwEAIC0EBDj4fc1RQkv8tRs9-i6Rlp-Cc5cMMAs7pVkzt2edp8sTo1mLIRuNDr02H1KFfOvB2ci-Ae71XL1donNq-KO44huL3dpn_SyRJuBPIea_txqJOVOuRbgtpSOKDNVyVLvOEhSU-ALUui6sgtfmdG_0htcrOyqfbkFmpiGz8/s320/KakaoTalk_20230827_155915268.jpg" 
                                    alt="wrappixel kit" />
                                    </a>
                                <CardBody>
                                    <h5 className="font-medium m-b-0">MISSHA</h5>
                                    <p className="m-b-0 font-14">2023-08-05 </p>
                                    <p className="m-b-0 font-14">Location : Vietnam</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PortfolioComponent;
