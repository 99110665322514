import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner3 Career.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import Table from "./sections/table.jsx";
import C2acomp from "../Career/sections/c2acomp.jsx";
import TeamComponent from "../Career/sections/teamcomponent.jsx";


const Components = () => {
    return (
        <div id="Career">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                <HeaderBanner />
                    <Table/>
                    <C2acomp />
                    <TeamComponent />
                </div>
            </div>
            <Footer />
        </div>
    );
}


Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
