/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

const PricingComponent = () => {
    return (
        <div>
            <div className="pricing8 mini-spacer b-t" id="Offer">
                   <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Offer process</h1>
                            {/* <h6 className="subtitle">Here you can check Demos we created based on WrapKit. Its quite easy to Create your own dream website &amp; dashboard in No-time.</h6> */}
                        </Col>
                    </Row>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            {/* <h2 className="title">보편적인 진행방식</h2> */}
                            <h6 className="subtitle">We offer 100% satisafaction and Money back Guarantee</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="4" className="ms-auto pricing-box align-self-center">
                            <Card className="b-all">
                                <CardBody className="p-30 text-center">
                                    <h5>Regular Plan</h5>
                                    <sup>$</sup><span className="text-dark display-5">5,000</span>
                                   <h6 className="font-light font-14">for starter</h6>
                                
                                    <p className="m-t-40">
                                    ✅ EXW Incoterms terms. (korea)
                                     </p>
                                    <p className="m-t-40">
                                    ✅ T/T in Advanced.
                                      </p>
                                    <p className="m-t-40">
                                    ✅ Provision of images for sales support
                                      </p>
                                    <p className="m-t-40">
                                    ❌ mixed Brands
                                    </p>
                                </CardBody>
                                <a className="btn btn-info-gradiant p-15 btn-arrow btn-block"  href="mailto:krsales@bebrave.co.kr">Email</a>
                            </Card>
                        </Col>
                        <Col md="4" className="ms-auto pricing-box align-self-center">
                            <Card className="b-all">
                                <CardBody className="p-30 text-center">
                                    <h5>Bulk starter Plan</h5>
                                    <sup>$</sup><span className="text-dark display-5">10,000</span>
                                    <h6 className="font-light font-14">for Bulk starter / Live seller</h6>
                                    <p className="m-t-40">
                                    ✅ EXW/FOB (korea only)
                                     </p>
                                    <p className="m-t-40">
                                    ✅ T/T in Advanced.
                                      </p>
                                    <p className="m-t-40">
                                    ✅ Provision of images for sales support
                                      </p>
                                    <p className="m-t-40">
                                    ✅ mixed Brands
                                    </p>
                                 </CardBody>
                                <a className="btn btn-danger-gradiant p-15 btn-arrow btn-block" href="https://api.whatsapp.com/send/?phone=%2B821045919123&text&type=phone_number&app_absent=0">Whatsapp+ </a>
                            </Card>
                        </Col>
                        <Col md="4" className="ms-auto pricing-box align-self-center">
                            <Card className="b-all">
                                <CardBody className="p-30 text-center">
                                    <h5>Bulk+ Plan</h5>
                                    <sup>$</sup><span className="text-dark display-5">20,000</span>
                                    <h6 className="font-light font-14">wholesaler / local retail seller / online seller</h6>
                                    <p className="m-t-40">
                                    ✅ EWX/FOB/CIF (discuss details)
                                    </p>
                                    <p className="m-t-40">
                                    ✅ T/T in Advanced , L/C 
                                      </p>
                                    <p className="m-t-40">
                                    ✅ Provision of images for sales support
                                    </p>
                                    <p className="m-t-40">
                                    ✅ mixed Brands
                                    </p>
                                 </CardBody>
                                <a className="btn btn-warning p-15 btn-arrow btn-block" href="https://open.kakao.com/me/krsales"> Kakao </a>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PricingComponent;
